@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.brk-social-links {
  display: inline-flex;
  align-items: center;
	flex-wrap: wrap;

  &__item {
		margin-right: 20px;
		line-height: 24px;

		&:hover {
			color: inherit;
		}

		[dir=rtl] & {
			margin-right: 0;
			margin-left: 20px;
		}

    &:last-of-type {
      margin-right: 0;

			[dir=rtl] & {
				margin-left: 0;
			}
    }

		i {
			line-height: inherit;
		}
  } 
  &_wide {
    .brk-social-links__item {
      margin-right: 35px;
      [dir=rtl] & {
        margin-right: 0;
        margin-left: 35px;
      }
      &:last-of-type {
        margin-right: 0;
  
        [dir=rtl] & {
          margin-left: 0;
        }
      }
    }
  }
  &_bordered {
    .brk-social-links__item {
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid black;
    }
  }
	&_bordered-post {
		margin-right: -5px;
		margin-left: -5px;

		.brk-social-links__item {
			box-shadow: 0 0 0 2px #dedede;
			background-color: $white;
			transition: box-shadow .3s;
			border-radius: 4px;
			min-width: 40px;
			text-align: center;
			margin: 0 5px;

			@media (max-width: 575px) {
				margin: 5px;
			}

			&:hover {
				box-shadow: 0 0 0 2px var(--brand-primary), 0 5px 16px 0 rgba(#000, .2);

				i {
					color: var(--brand-primary);
				}
			}

			i {
				transition: color .3s;
			}
		}
	}
  &_rounded {
    .brk-social-links__item {
      width: 32px;
      height: 32px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.4;
      transition: .3s ease opacity, .3s ease background-color, .3s ease color;
			margin-right: 15px;

			[dir=rtl] & {
				margin-right: 0;
				margin-left: 20px;
			}

			&:last-of-type {
				margin-right: 0;

				[dir=rtl] & {
					margin-left: 0;
				}
			}

      &:hover {
        opacity: 1;
        background-color: var(--brand-primary);
        color: #fff;
      }
    }
  }
  &_opacity{

		&:hover {
			.brk-social-links__item:not(:hover) {
				opacity: .6;
			}
		}

    .brk-social-links__item {
      transition: .3s ease opacity;
      will-change: opacity;
      &:hover{
        color: inherit;
      }
    }
  }
}

.brk-social-links-wide {
  display: flex;
  flex-direction: column;
  &__link {
    display: flex;
    align-items: center;
    &:hover {
      color: inherit;
      text-decoration: none;
      color: currentColor !important; 
      p {
        text-decoration: underline;
      }
    }
  }
  &__icon-container {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    width: 38px;
    flex-shrink: 0;
    padding-left: 3px;
  }
  &__item-light{
    i{
      color: #fff !important;
    }
  }

  // modifiers
  &_vertical{
    flex-direction: row;
    flex-wrap: wrap;

    .brk-social-links-wide__icon-container {
      width: 30px;
    }

    .brk-social-links-wide__link {
      margin-right: 40px;
    }
  }
}
.brk-social-links-wide__item_light{
  i{
    color: #ffffff;
  }
  &:hover{
    i{
      color: inherit;
    }
  }
}
.brk-social-links-round {
	display: flex;
	align-items: center;
	padding: 5px 0;
	flex-wrap: wrap;

	&__link {
		display: flex;
		align-items: center;
		color: #fff;
		padding: 0 10px;

		@media (max-width: 767px) {
			padding: 10px;
		}

		&:first-child {
			@media (min-width: 768px) {
				padding-left: 0;
			}
		}

		&:last-child {
			@media (min-width: 768px) {
				padding-right: 0;
			}
		}

		&:hover {
			color: #fff;
			
			.brk-social-links-round__icon {
				i {
					transform: translate(-50%, -50%) scale(1.2);
				}
			}
		}
	}

	&__icon {
		width: 46px;
		height: 46px;
		border-radius: 50%;
		background-color: #fff;
		position: relative;
		
		i {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #000;
			font-size: rem(18);
			line-height: 18px;
			transition: all .1s linear;
		}
	}

	&__title {
		font-size: rem(14);
		line-height: 16px;
		padding-left: 15px;
	}
}
