.brk-social-links {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap; }
  .brk-social-links__item {
    margin-right: 20px;
    line-height: 24px; }
    .brk-social-links__item:hover {
      color: inherit; }
    [dir=rtl] .brk-social-links__item {
      margin-right: 0;
      margin-left: 20px; }
    .brk-social-links__item:last-of-type {
      margin-right: 0; }
      [dir=rtl] .brk-social-links__item:last-of-type {
        margin-left: 0; }
    .brk-social-links__item i {
      line-height: inherit; }
  .brk-social-links_wide .brk-social-links__item {
    margin-right: 35px; }
    [dir=rtl] .brk-social-links_wide .brk-social-links__item {
      margin-right: 0;
      margin-left: 35px; }
    .brk-social-links_wide .brk-social-links__item:last-of-type {
      margin-right: 0; }
      [dir=rtl] .brk-social-links_wide .brk-social-links__item:last-of-type {
        margin-left: 0; }
  .brk-social-links_bordered .brk-social-links__item {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black; }
  .brk-social-links_bordered-post {
    margin-right: -5px;
    margin-left: -5px; }
    .brk-social-links_bordered-post .brk-social-links__item {
      box-shadow: 0 0 0 2px #dedede;
      background-color: #fff;
      transition: box-shadow .3s;
      border-radius: 4px;
      min-width: 40px;
      text-align: center;
      margin: 0 5px; }
      @media (max-width: 575px) {
        .brk-social-links_bordered-post .brk-social-links__item {
          margin: 5px; } }
      .brk-social-links_bordered-post .brk-social-links__item:hover {
        box-shadow: 0 0 0 2px var(--brand-primary), 0 5px 16px 0 rgba(0, 0, 0, 0.2); }
        .brk-social-links_bordered-post .brk-social-links__item:hover i {
          color: var(--brand-primary); }
      .brk-social-links_bordered-post .brk-social-links__item i {
        transition: color .3s; }
  .brk-social-links_rounded .brk-social-links__item {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.4;
    transition: .3s ease opacity, .3s ease background-color, .3s ease color;
    margin-right: 15px; }
    [dir=rtl] .brk-social-links_rounded .brk-social-links__item {
      margin-right: 0;
      margin-left: 20px; }
    .brk-social-links_rounded .brk-social-links__item:last-of-type {
      margin-right: 0; }
      [dir=rtl] .brk-social-links_rounded .brk-social-links__item:last-of-type {
        margin-left: 0; }
    .brk-social-links_rounded .brk-social-links__item:hover {
      opacity: 1;
      background-color: var(--brand-primary);
      color: #fff; }
  .brk-social-links_opacity:hover .brk-social-links__item:not(:hover) {
    opacity: .6; }
  .brk-social-links_opacity .brk-social-links__item {
    transition: .3s ease opacity;
    will-change: opacity; }
    .brk-social-links_opacity .brk-social-links__item:hover {
      color: inherit; }

.brk-social-links-wide {
  display: flex;
  flex-direction: column; }
  .brk-social-links-wide__link {
    display: flex;
    align-items: center; }
    .brk-social-links-wide__link:hover {
      color: inherit;
      text-decoration: none;
      color: currentColor !important; }
      .brk-social-links-wide__link:hover p {
        text-decoration: underline; }
  .brk-social-links-wide__icon-container {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    width: 38px;
    flex-shrink: 0;
    padding-left: 3px; }
  .brk-social-links-wide__item-light i {
    color: #fff !important; }
  .brk-social-links-wide_vertical {
    flex-direction: row;
    flex-wrap: wrap; }
    .brk-social-links-wide_vertical .brk-social-links-wide__icon-container {
      width: 30px; }
    .brk-social-links-wide_vertical .brk-social-links-wide__link {
      margin-right: 40px; }

.brk-social-links-wide__item_light i {
  color: #ffffff; }

.brk-social-links-wide__item_light:hover i {
  color: inherit; }

.brk-social-links-round {
  display: flex;
  align-items: center;
  padding: 5px 0;
  flex-wrap: wrap; }
  .brk-social-links-round__link {
    display: flex;
    align-items: center;
    color: #fff;
    padding: 0 10px; }
    @media (max-width: 767px) {
      .brk-social-links-round__link {
        padding: 10px; } }
    @media (min-width: 768px) {
      .brk-social-links-round__link:first-child {
        padding-left: 0; } }
    @media (min-width: 768px) {
      .brk-social-links-round__link:last-child {
        padding-right: 0; } }
    .brk-social-links-round__link:hover {
      color: #fff; }
      .brk-social-links-round__link:hover .brk-social-links-round__icon i {
        transform: translate(-50%, -50%) scale(1.2); }
  .brk-social-links-round__icon {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background-color: #fff;
    position: relative; }
    .brk-social-links-round__icon i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #000;
      font-size: 1.125rem;
      line-height: 18px;
      transition: all .1s linear; }
  .brk-social-links-round__title {
    font-size: 0.875rem;
    line-height: 16px;
    padding-left: 15px; }
